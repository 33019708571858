@media screen and (orientation: portrait) {
  .para2 {
    background-image: url('../../../../assets/home-page/para2-vertival.jpg');
  }
}

@media screen and (orientation: landscape) {
  .para2 {
    background-image: url('../../../../assets/home-page/para2.jpg');
  }
}
