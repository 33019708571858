.--general-btn {
  display: inline-block;
  padding: 1rem 4rem;
  border-radius: 9px;
  text-decoration: none;
  /*border: 1px solid var(--main-color2);*/
  transition: all 0.3s;
  margin-top: 2rem;
  cursor: pointer;
}

.--general-btn:hover {
  display: inline-block;
  padding: 1rem 4rem;
  border-radius: 9px;
  /*background-color: var(--main-color2-80-tin);*/
  text-decoration: none;
  /*border: 1px solid var(--white);*/
  transform: scale(1.05);
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.6);
}

.--general-btn span {
  font-size: 2rem;
  letter-spacing: 0.2rem;
  font-family: var(--font-family-one);
  font-weight: 700;
  /*-webkit-text-stroke: 1px var(--main-color2);*/
}
