.section-hero {
  min-height: 100vh;
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 95fr 5fr;
  background-image: url('../../../../assets/home-page/hero.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hero-decor {
  width: 1.5rem;
  height: 100%;
  background-color: var(--main-color2);
  transform: translateX(50%);
}

.hero-decor-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 15rem;
}

.hero-h1 {
  font-size: 18rem;
  font-weight: 700;
  font-family: var(--font-family-one);
  color: var(--main-color2);
  transition: all 1.2s ease-out;
  transform: translateX(25%);
  opacity: 0;
}

.hero-p {
  font-size: 2rem;
  font-weight: 500;
  font-family: var(--font-family-one);
  color: var(--gray-text);
  transition: all 1s ease-out;
  transform: translateX(25%);
  opacity: 0;
  margin-bottom: 1rem;
}

.hero-h2 {
  font-size: 4.5rem;
  font-weight: 700;
  font-family: var(--font-family-one);
  color: var(--white);
  transition: all 1s ease-out;
  transform: translateX(25%);
  opacity: 0;
}

.show-hero {
  opacity: 1;
  transform: translateX(0);
}
