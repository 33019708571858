@media screen and (orientation: portrait) {
  /*1400px*/
  @media (max-width: 87.5em) {
    .gallery-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    .g-img-16 {
      display: none;
      visibility: hidden;
    }

    .g-img-15 {
      display: none;
      visibility: hidden;
    }

    .g-img-14 {
      display: none;
      visibility: hidden;
    }

    .g-img-13 {
      display: none;
      visibility: hidden;
    }

    .profile-title {
      margin-left: 2rem;
    }

    .profile-body p {
      margin-bottom: 4rem;
      font-size: 2rem;
    }

    .img-show-1 {
      width: 5rem;
      height: 5rem;
    }
    .img-show-2 {
      width: 5rem;
      height: 5rem;
      margin-left: -1.5rem;
    }
    .img-show-3 {
      width: 5rem;
      height: 5rem;
      margin-left: -1.5rem;
    }
    .img-show-4 {
      width: 5rem;
      height: 5rem;
      margin-left: -1.5rem;
    }
    .img-show-5 {
      width: 5rem;
      height: 5rem;
      margin-left: -1.5rem;
    }
  }

  /*1200px*/
  @media (max-width: 75em) {
    .profile-link:link,
    .profile-link:visited {
      position: unset;
      padding: 1.5rem 2.5rem;
    }

    .profile-link span {
      font-size: 1.3rem;
      letter-spacing: 0.2rem;
      font-family: var(--font-family-one);
      font-weight: 700;
      -webkit-text-stroke: 1px var(--main-color2);
    }
  }

  /*1000px*/
  @media (max-width: 62.5em) {
    .gallery-grid {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    .g-img-12 {
      display: none;
      visibility: hidden;
    }

    .g-img-11 {
      display: none;
      visibility: hidden;
    }

    .g-img-10 {
      display: none;
      visibility: hidden;
    }

    .g-img-9 {
      display: none;
      visibility: hidden;
    }

    .g-img-8 {
      display: none;
      visibility: hidden;
    }

    .g-img-7 {
      display: none;
      visibility: hidden;
    }

    .image-line-up-text p {
      font-size: 2rem;
      margin-left: 2rem;
    }

    .profile-img {
      width: 12rem;
      height: 12rem;
    }
  }

  /*950px*/
  @media (max-width: 59em) {
    .profile-body p {
      margin-bottom: 2rem;
      font-size: 1.8rem;
    }

    .image-line-up-text p {
      font-size: 1.2rem;
      margin-left: 2rem;
    }
  }

  /*800px*/
  @media (max-width: 50em) {
    .testimonial-gallery {
      display: none;
      visibility: hidden;
    }

    .testimonial-text {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }

    .profile-header {
      grid-template-columns: 1fr;
      gap: 3rem;
    }

    .profile-img-container {
      justify-content: center;
    }

    .profile-title {
      align-items: center;
      text-align: center;
      margin-left: unset;
    }

    .profile-body {
      justify-content: center;
      align-items: center;
    }

    .profile-body p {
      font-size: 3.6rem;
      margin-bottom: 8rem;
    }

    .img-show-1 {
      width: 8rem;
      height: 8rem;
    }
    .img-show-2 {
      width: 8rem;
      height: 8rem;
      margin-left: -3rem;
    }
    .img-show-3 {
      width: 8rem;
      height: 8rem;
      margin-left: -3rem;
    }
    .img-show-4 {
      width: 8rem;
      height: 8rem;
      margin-left: -3rem;
    }
    .img-show-5 {
      width: 8rem;
      height: 8rem;
      margin-left: -3rem;
    }

    .image-line-up-text p {
      font-size: 2.4rem;
    }
  }

  /*700px*/
  @media (max-width: 44em) {
    .profile-link:link,
    .profile-link:visited {
      margin-bottom: 2rem;
    }
  }

  /*600px*/
  @media (max-width: 37.5em) {
    .profile-body p {
      font-size: 3rem;
    }

    .img-show-1 {
      width: 6rem;
      height: 6rem;
    }
    .img-show-2 {
      width: 6rem;
      height: 6rem;
      margin-left: -2.5rem;
    }
    .img-show-3 {
      width: 6rem;
      height: 6rem;
      margin-left: -2.5rem;
    }
    .img-show-4 {
      width: 6rem;
      height: 6rem;
      margin-left: -2.5rem;
    }
    .img-show-5 {
      width: 6rem;
      height: 6rem;
      margin-left: -2.5rem;
    }

    .image-line-up-text p {
      font-size: 1.8rem;
    }
  }

  /*500px*/
  @media (max-width: 31em) {
    .profile-link:link,
    .profile-link:visited {
      padding: 1rem 2rem;
    }

    .profile-link span {
      font-size: 1rem;
    }

    .testimonial-text {
      padding: 8rem 4rem 8rem 4rem;
    }
  }

  /*400px*/
  @media (max-width: 25em) {
    .profile-body p {
      font-size: 2rem;
    }

    .img-show-1 {
      width: 4rem;
      height: 4rem;
    }
    .img-show-2 {
      width: 4rem;
      height: 4rem;
      margin-left: -2rem;
    }
    .img-show-3 {
      width: 4rem;
      height: 4rem;
      margin-left: -2rem;
    }
    .img-show-4 {
      width: 4rem;
      height: 4rem;
      margin-left: -2rem;
    }
    .img-show-5 {
      width: 4rem;
      height: 4rem;
      margin-left: -2rem;
    }

    .image-line-up-text p {
      font-size: 1.4rem;
    }
  }
}

@media screen and (orientation: landscape) {
  /*1400px*/
  @media (max-width: 87.5em) {
    .gallery-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    .g-img-16 {
      display: none;
      visibility: hidden;
    }

    .g-img-15 {
      display: none;
      visibility: hidden;
    }

    .g-img-14 {
      display: none;
      visibility: hidden;
    }

    .g-img-13 {
      display: none;
      visibility: hidden;
    }

    .profile-title {
      margin-left: 2rem;
    }

    .profile-body p {
      margin-bottom: 4rem;
      font-size: 2rem;
    }

    .img-show-1 {
      width: 5rem;
      height: 5rem;
    }
    .img-show-2 {
      width: 5rem;
      height: 5rem;
      margin-left: -1.5rem;
    }
    .img-show-3 {
      width: 5rem;
      height: 5rem;
      margin-left: -1.5rem;
    }
    .img-show-4 {
      width: 5rem;
      height: 5rem;
      margin-left: -1.5rem;
    }
    .img-show-5 {
      width: 5rem;
      height: 5rem;
      margin-left: -1.5rem;
    }
  }

  /*1200px*/
  @media (max-width: 75em) {
    .profile-link:link,
    .profile-link:visited {
      position: unset;
      padding: 1.5rem 2.5rem;
    }

    .profile-link span {
      font-size: 1.3rem;
      letter-spacing: 0.2rem;
      font-family: var(--font-family-one);
      font-weight: 700;
      -webkit-text-stroke: 1px var(--main-color2);
    }
  }

  /*1000px*/
  @media (max-width: 62.5em) {
    .gallery-grid {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    .g-img-12 {
      display: none;
      visibility: hidden;
    }

    .g-img-11 {
      display: none;
      visibility: hidden;
    }

    .g-img-10 {
      display: none;
      visibility: hidden;
    }

    .g-img-9 {
      display: none;
      visibility: hidden;
    }

    .g-img-8 {
      display: none;
      visibility: hidden;
    }

    .g-img-7 {
      display: none;
      visibility: hidden;
    }

    .image-line-up-text p {
      font-size: 2rem;
      margin-left: 2rem;
    }

    .profile-img {
      width: 12rem;
      height: 12rem;
    }
  }

  /*1200px*/
  @media (max-width: 75em) {
    .profile-link:link,
    .profile-link:visited {
      position: unset;
      padding: 1.5rem 2.5rem;
      margin-bottom: 1rem;
    }

    .profile-link span {
      font-size: 1.3rem;
      letter-spacing: 0.2rem;
      font-family: var(--font-family-one);
      font-weight: 700;
      -webkit-text-stroke: 1px var(--main-color2);
    }
  }

  /*950px*/
  @media (max-width: 59em) {
    .profile-body p {
      margin-bottom: 2rem;
      font-size: 1.8rem;
    }

    .image-line-up-text p {
      font-size: 1.2rem;
      margin-left: 2rem;
    }
  }

  /*800px*/
  @media (max-width: 50em) {
    .profile-img {
      width: 10rem;
      height: 10rem;
    }

    .profile-title h4 {
      font-size: 3rem;
    }

    .profile-title p {
      font-size: 1.6rem;
    }

    .profile-body p {
      font-size: 1.4rem;
    }

    .image-line-up-text p {
      font-size: 1rem;
      margin-left: 1.5rem;
    }

    .gallery-grid {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    .g-img-10 {
      display: unset;
      visibility: unset;
    }

    .g-img-9 {
      display: unset;
      visibility: unset;
    }

    .g-img-11 {
      display: unset;
      visibility: unset;
    }
  }

  /*700px*/
  @media (max-width: 44em) {
    .profile-link:link,
    .profile-link:visited {
      padding: 1rem 2rem;
      margin-bottom: 2rem;
    }
  }

  /*500px*/
  @media (max-width: 31em) {
    .profile-link span {
      font-size: 1rem;
    }
  }

  /*400px*/
  @media (max-width: 25em) {
    .profile-link span {
      font-size: 0.7rem;
    }
  }
}
