@media screen and (orientation: portrait) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9.6rem;
    padding: 0 4.8rem;
    z-index: 9998;
    transition: all 0.5s ease-in;
    position: absolute;
    width: 100vw;
  }

  .logo {
    height: 6rem;
  }

  /*****************************/
  /* nav */
  /*****************************/
  .main-nav-list {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 4.8rem;
  }

  .mobile-nav {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 9.6rem 2rem 2rem 2rem;
    height: 100%;
    margin-top: -9.6rem;
  }

  .font-size-bigger {
    font-size: 3.2rem !important;
  }

  .font-size-2-rem {
    font-size: 2rem !important;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    display: inline-block;
    text-decoration: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.8rem;
    transition: all 0.3s ease;
  }

  .main-nav-link:hover,
  .main-nav-link:active {
    color: var(--main-color2-20-tin);
    transform: scale(1.1);
  }

  .nav-cta:hover,
  .main-nav-link:active {
    transform: none;
  }

  .main-nav-link.nav-cta:link,
  .main-nav-link.nav-cta:visited {
    display: inline-block;
    padding: 1.2rem 2.4rem;
    border-radius: 9px;
    color: var(--text-color-grays2);
    background-color: var(--main-color2-20-tin);
  }

  .main-nav-link.nav-cta:hover,
  .main-nav-link.nav-cta:active {
    background-color: var(--main-color2-40-tin);
    color: var(--white);
  }

  /********** SUB MAIN NAV *************/
  .main-nav-sub-container {
    position: absolute;
    display: flex;
    visibility: hidden;
    padding-top: 3rem;
    transition: all 0.3s;
    transform: translateX(-1.5rem) translateY(-0.2rem);
  }

  .relative {
    position: relative;
  }

  .left-150 {
    left: 150%;
  }

  .bottom-0 {
    bottom: 0;
  }

  .main-nav-link:hover ~ .main-nav-sub-container {
    visibility: visible;
  }

  .main-nav-sub-container:hover {
    visibility: visible;
  }

  .main-sub-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;
    list-style: none;
    padding: 0 1rem 1rem 1rem;
  }

  .main-sub-list.bg {
    background-color: var(--dark-background-color);
  }

  .main-sub-link:link,
  .main-sub-link:visited {
    display: inline-block;
    text-decoration: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.8rem;
    transition: transform 0.3s ease;
  }

  .main-sub-link:hover,
  .main-sub-link:active {
    color: var(--main-color2-20-tin);
    transform: scale(1.1);
  }
  /* css for button render instead of a tag */
  .main-nav-link-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.8rem;
    transition: all 0.3s ease;
    width: 100%;
    display: flex;
  }

  .logout-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.8rem;
    width: 100%;
    display: flex;
  }

  .logout-btn:hover {
    color: var(--main-color2-20-tin);
    transform: scale(1.05);
    transition: all 0.3s ease;
  }

  .main-nav-link-btn:hover {
    color: var(--main-color2-20-tin);
    transform: scale(1.1);
  }

  .main-nav-link-btn:hover ~ .main-nav-sub-container {
    visibility: visible;
  }
  /*---------------------------------------*/

  .active-link {
    color: var(--main-color2-20-tin) !important;
  }

  .menu-icon {
    color: var(--main-color2-20-tin);
    height: 5.2rem;
    width: 5.2rem;
  }

  /* Sticky nav */
  .sticky {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--dark-background-color);
    padding-top: 0;
    padding-bottom: 0;
    height: 8rem;
    box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
  }

  /* mobile */
  .mobile-open-menu {
    position: fixed;
    /*background-color: var(--white);*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0%);
    transition: all 0.3s ease-in;

    opacity: 0.95;

    pointer-events: auto;
    visibility: visible;
    z-index: 9999;
  }

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .mobile-open-menu {
      background-color: rgba(255, 255, 255, 0.95);
      -webkit-backdrop-filter: blur(1em);
      backdrop-filter: blur(1em);
    }
  }

  .mobile-closed-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: all 0.3s ease-in;

    opacity: 0;

    pointer-events: none;
    visibility: hidden;
  }

  .main-nav {
  }

  .x-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 9.6rem;
    padding: 0 2.4rem;
  }

  .main-nav-open-mobile {
    height: 100%;
    background-color: var(--dark-background-color);
  }
}

@media screen and (orientation: landscape) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9.6rem;
    padding: 0 4.8rem;
    z-index: 9998;
    transition: all 0.5s ease-in;
    position: absolute;
    width: 100vw;
  }

  .logo {
    height: 6rem;
  }

  /*****************************/
  /* nav */
  /*****************************/
  .main-nav-list {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 4.8rem;
    flex-wrap: wrap;
    padding: 6rem;
    /*position: relative;*/
  }

  /*
  .mobile-nav {
    justify-content: center;
    height: 100%;
    margin-top: -9.6rem;
  }

   */

  .mobile-nav {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 9.6rem 2rem 2rem 2rem;
    height: 100%;
    margin-top: -9.6rem;
  }

  .font-size-bigger {
    font-size: 2rem !important;
  }

  .font-size-2-rem {
    font-size: 1.6rem !important;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    display: inline-block;
    text-decoration: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.8rem;
    transition: all 0.3s ease;
  }

  .main-nav-link:hover,
  .main-nav-link:active {
    color: var(--main-color2-20-tin);
    transform: scale(1.1);
  }

  /********** SUB MAIN NAV *************/
  .main-nav-sub-container {
    position: absolute;
    display: flex;
    visibility: hidden;
    padding-top: 3rem;
    transition: all 0.3s;
    transform: translateX(-1.5rem) translateY(-0.2rem);
  }

  .relative {
    position: relative;
  }

  .left-150 {
    right: 0;
  }

  .bottom-0 {
    top: 100%;
  }

  .main-nav-link:hover ~ .main-nav-sub-container {
    visibility: visible;
  }

  .main-nav-sub-container:hover {
    visibility: visible;
  }

  .main-sub-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /*flex-direction: column;*/
    gap: 3rem;
    list-style: none;
    padding: 0 1rem 1rem 1rem;
  }

  .main-sub-list.bg {
    background-color: var(--dark-background-color);
  }

  .main-sub-link:link,
  .main-sub-link:visited {
    display: inline-block;
    text-decoration: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.8rem;
    transition: transform 0.3s ease;
  }

  .main-sub-link:hover,
  .main-sub-link:active {
    color: var(--main-color2-20-tin);
    transform: scale(1.05);
  }

  /* css for button render instead of a tag */
  .main-nav-link-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.8rem;
    transition: all 0.3s ease;
    width: 100%;
    display: flex;
  }

  .logout-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.8rem;
    width: 100%;
    display: flex;
  }

  .logout-btn:hover {
    color: var(--main-color2-20-tin);
    transform: scale(1.05);
    transition: all 0.3s ease;
  }

  .main-nav-link-btn:hover {
    color: var(--main-color2-20-tin);
    transform: scale(1.05);
  }

  .main-nav-link-btn:hover ~ .main-nav-sub-container {
    visibility: visible;
  }
  /*---------------------------------------*/
  .nav-cta:hover,
  .main-nav-link:active {
    transform: none;
  }

  .main-nav-link.nav-cta:link,
  .main-nav-link.nav-cta:visited {
    display: inline-block;
    padding: 1.2rem 2.4rem;
    border-radius: 9px;
    color: var(--text-color-grays2);
    background-color: var(--main-color2-20-tin);
  }

  .main-nav-link.nav-cta:hover,
  .main-nav-link.nav-cta:active {
    background-color: var(--main-color2-40-tin);
    color: var(--white);
  }

  .active-link {
    color: var(--main-color2-20-tin) !important;
  }

  .menu-icon {
    color: var(--main-color2-20-tin);
    height: 5.2rem;
    width: 5.2rem;
  }

  /* Sticky nav */
  .sticky {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--dark-background-color);
    padding-top: 0;
    padding-bottom: 0;
    height: 8rem;
    box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
  }

  /* mobile */
  .mobile-open-menu {
    position: fixed;
    /*background-color: var(--white);*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0%);
    transition: all 0.3s ease-in;

    opacity: 0.95;

    pointer-events: auto;
    visibility: visible;
    z-index: 9999;
  }

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .mobile-open-menu {
      background-color: rgba(255, 255, 255, 0.95);
      -webkit-backdrop-filter: blur(1em);
      backdrop-filter: blur(1em);
    }
  }

  .mobile-closed-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: all 0.3s ease-in;

    opacity: 0;

    pointer-events: none;
    visibility: hidden;
  }

  .main-nav {
  }

  .x-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 9.6rem;
    padding: 0 2.4rem;
  }

  .main-nav-open-mobile {
    height: 100%;
    background-color: var(--dark-background-color);
  }
}
