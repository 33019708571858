@media screen and (orientation: portrait) {
  .para3 {
    background-image: url('../../../../assets/home-page/para3-vertical.jpg');
  }
}

@media screen and (orientation: landscape) {
  .para3 {
    background-image: url('../../../../assets/home-page/para3.jpg');
  }
}
