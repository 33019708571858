@media screen and (orientation: portrait) {
  /*800px*/
  @media (max-width: 50em) {
    .results-hero {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .results-hero-img-big h1 {
      font-size: 9rem;
    }

    .results-hero-img-big h2 {
      font-size: 5rem;
    }
    .body-header-res {
      flex-direction: column;
    }
    .body-img-container {
      width: 15rem;
    }
  }

  /*500px*/
  @media (max-width: 31.25em) {
    .results-hero {
      grid-template-columns: 1fr 1fr;
    }

    .results-hero-img-big h1 {
      font-size: 8rem;
    }

    .results-hero-img-big h2 {
      font-size: 4rem;
    }
  }
}

@media screen and (orientation: landscape) {
  /*800px*/
  @media (max-width: 50em) {
    .results-hero {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .results-hero-img-big h1 {
      font-size: 9rem;
    }

    .results-hero-img-big h2 {
      font-size: 5rem;
    }
  }

  /*500px*/
  @media (max-width: 31.25em) {
    .results-hero {
      grid-template-columns: 1fr 1fr;
    }

    .results-hero-img-big h1 {
      font-size: 8rem;
    }

    .results-hero-img-big h2 {
      font-size: 4rem;
    }
  }
}
