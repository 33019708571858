.how-header-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-background-color);
  padding: 3.2rem 0 3.2rem 0;
}

.hhs-h1 {
  font-weight: 700;
  font-size: 9.8rem;
  line-height: 1.5;
  color: var(--white);
  transition: all 1.5s ease-out;
  text-align: center;
  margin-bottom: 4.8rem;
  opacity: 0;
}

.hhs-p {
  font-weight: 700;
  letter-spacing: 0.2rem;
  font-size: 2.4rem;
  line-height: 1.8;
  text-align: center;
  color: var(--white-low-opacity);
  transition: all 1s ease-out;
  opacity: 0;
}

.show {
  opacity: 1;
}
