.main-map {
  display: flex;
  flex-direction: column;
}
.section-road-map {
  min-height: 100vh;
  background-color: var(--dark-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.2rem 0 3.2rem 0;
}

.extra-margin-top {
  margin-top: 18rem;
}

.map-grid {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 9.6rem;
}

.road-map-grid--2--col {
  grid-template-columns: 70fr 30fr;
}

.road-map-grid--2--col-right {
  grid-template-columns: 30fr 70fr;
}

.how-img {
  width: 100%;
  border-radius: 11px;
}

.map-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1.8rem;
  z-index: 2;
}

.map-list-item {
  margin-left: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--white-low-opacity);
}

.map-text-container h2 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.5;
  color: var(--white);
  transition: all 2s ease-in;
}

.map-text {
  font-weight: 700;
  letter-spacing: 0.2rem;
  font-size: 2rem;
  line-height: 1.8;
  color: var(--white-low-opacity);
}

.sub-text-title {
  font-weight: 500;
  letter-spacing: 0.2rem;
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--white-low-opacity);
}

.map-list-item {
  margin-bottom: 1rem;
}

.map-f-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.map-f-img-container::before,
.map-f-img-container::after {
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
}

.map-f-img-container::before {
  width: 46rem;
  height: 46rem;
  padding-bottom: 50%;
  background-color: var(--main-color2-low-opacity-20);
}

.map-f-img-container::after {
  width: 40rem;
  height: 40rem;
  padding-bottom: 50%;
  background-color: var(--main-color2-low-opacity-20);
  z-index: 1;
}

.map-img-container1 {
  background-image: url('../../how-assets/first.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 11px;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 1px solid var(--main-color2);
}

.map-img-container2 {
  background-image: url('../../how-assets/second.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 11px;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 1px solid var(--main-color2);
}

.map-img-container3 {
  background-image: url('../../how-assets/third.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 11px;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 1px solid var(--main-color2);
}

.map-img-container4 {
  background-image: url('../../how-assets/forth.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 11px;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 1px solid var(--main-color2);
}
