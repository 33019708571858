.section-booking {
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.booking-free {
  position: absolute;
  color: var(--white);
  background-color: var(--main-color2);
  right: 0;
  top: 0;
  width: 20%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg) translateY(-40%) translateX(30%);
  box-shadow: 0 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
}

.booking-free span {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.booking-grid {
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 25fr 75fr;
  padding: 8rem 6rem 8rem 6rem;
  position: relative;
}

.booking-grid::after {
  content: '';
  background-color: var(--dark-background-color);
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: -1;
  right: 0;
}

.booking-grid::before {
  content: '';
  background-color: var(--text-color-grays);
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.booking-img {
  background-image: url('../../../../assets/home-page/booking.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  border: 2px solid var(--main-color2);
}

.booking-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-column: 1 / -1;
}

.booking-h4-wrap {
  background-image: linear-gradient(var(--main-color2) 5%, var(--white));
  background-clip: text;
  color: transparent;
}

.booking-header-h4 {
  font-family: var(--font-family-one);
  font-weight: 700;
  font-size: 8rem;
  /*color: var(--white);*/
}

.booking-img-container-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6rem 0 0;
}

.booking-text-container-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 6rem;
}

.booking-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem;
  width: 100%;
  height: 100%;
}

.booking-content p {
  color: var(--gray-text);
  font-size: 2.4rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
}
