/*1200px*/
@media (max-width: 75em) {
  .how-header-section h1 {
    font-size: 7.4rem;
  }
}

/*900px*/
@media (max-width: 56em) {
  .how-header-section h1 {
    font-size: 6.2rem;
  }

  .how-header-section p {
    font-size: 1.8rem;
  }
}

/*700px*/
@media (max-width: 44em) {
  .how-header-section h1 {
    font-size: 5.2rem;
  }

  .how-header-section p {
    font-size: 1.8rem;
  }
}

/*550px*/
@media (max-width: 35em) {
  .how-header-section h1 {
    font-size: 4.4rem;
  }

  .how-header-section p {
    font-size: 1.6rem;
  }
}

/*360px*/
@media (max-width: 23em) {
  .how-header-section h1 {
    font-size: 3.6rem;
  }

  .how-header-section p {
    font-size: 1.6rem;
  }
}
