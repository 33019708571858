@media screen and (orientation: portrait) {
  /*1250px*/
  @media (max-width: 78em) {
    .hero-decor-container {
      height: 0;
    }

    .hero-h1 {
      font-size: 9rem;
    }

    .hero-h2 {
      font-size: 2.5rem;
    }
  }

  /*900px*/
  @media (max-width: 56em) {
    .section-hero {
      background-position: 80% 100%;
      grid-template-rows: 65fr 35fr;
    }
  }

  /*710px*/
  @media (max-width: 44em) {
    .section-hero {
      grid-template-columns: 1fr;
      grid-template-rows: 90fr 5fr 5fr;
      padding: 2.4rem;
      text-align: center;
    }

    .content {
      margin-left: 0;
      align-self: flex-end;
    }

    .hero-h1 {
      font-size: 9rem;
    }

    .hero-h2 {
      font-size: 2.5rem;
    }
  }
}

@media screen and (orientation: landscape) {
  /*1250px*/
  @media (max-width: 78em) {
    .hero-decor-container {
      height: 0;
    }
  }

  /*900px*/
  @media (max-width: 56em) {
    .section-hero {
      background-position: 85% 100%;
      grid-template-rows: 95fr 5fr;
    }

    .hero-h1 {
      font-size: 9rem;
    }

    .hero-h2 {
      font-size: 2.5rem;
    }
  }

  /*710px*/
  @media (max-width: 44em) {
    .section-hero {
      grid-template-columns: 1fr;
      padding: 2.4rem;
      text-align: center;
    }

    .content {
      margin-left: 0;
    }
  }
}
