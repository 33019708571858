.section-testimonial {
  min-height: 100vh;
  display: flex;
  width: 100%;
}

.testimonial-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--dark-background-color);
  width: 50%;
  padding: 8rem 6rem 8rem 6rem;
}

.testimonial-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--text-color-grays);
  width: 50%;
  padding: 8rem 6rem 8rem 6rem;
}

/******PROFILE******/

.testimonial-profile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.profile-header {
  display: grid;
  grid-template-columns: 30fr 70fr;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.profile-img-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.profile-img {
  background-image: url('../../../../assets/home-page/testimonial-main.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 15rem;
  height: 15rem;
  border: 2px solid var(--main-color2);
}

.profile-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
  /*text-align: center;*/
}

.profile-title h4 {
  color: var(--white);
  font-size: 4.4rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
}

.profile-title p {
  color: var(--gray-text);
  font-size: 2.4rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
}

.profile-body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  padding-bottom: 2rem;
}

.profile-body p {
  color: var(--gray-text);
  font-size: 2.4rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
  margin-bottom: 8rem;
}

.profile-link span {
  font-size: 2rem;
  letter-spacing: 0.2rem;
  font-family: var(--font-family-one);
  font-weight: 700;
  -webkit-text-stroke: 1px var(--main-color2);
}

.profile-link:link,
.profile-link:visited {
  display: inline-block;
  padding: 2rem 4rem;
  border-radius: 9px;
  background-color: var(--white);
  text-decoration: none;
  border: 1px solid var(--main-color2);
  transition: all 0.3s;
}

.profile-link:hover,
.profile-link:active {
  display: inline-block;
  padding: 2rem 4rem;
  border-radius: 9px;
  background-color: var(--main-color2-80-tin);
  text-decoration: none;
  border: 1px solid var(--white);
  transform: scale(1.05);
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.6);
}

/***img show ***/

.image-show {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.image-line-up {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.img-show-1 {
  background-image: url('../../../../assets/home-page/testimonial-img-show-1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  border: 2px solid var(--main-color2);
}
.img-show-2 {
  background-image: url('../../../../assets/home-page/testimonial-img-show-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  border: 2px solid var(--main-color2);
  margin-left: -3rem;
}
.img-show-3 {
  background-image: url('../../../../assets/home-page/testimonial-img-show-3.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  border: 2px solid var(--main-color2);
  margin-left: -3rem;
}
.img-show-4 {
  background-image: url('../../../../assets/home-page/testimonial-img-show-4.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  border: 2px solid var(--main-color2);
  margin-left: -3rem;
}
.img-show-5 {
  background-image: url('../../../../assets/home-page/testimonial-img-show-5.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  border: 2px solid var(--main-color2);
  margin-left: -3rem;
}

.image-line-up-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.image-line-up-text p {
  color: var(--main-color2);
  font-size: 2.4rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
  margin-left: 4rem;
}

/*****************GALLERY**********************/

.gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: 2rem;
}

.g-img-1 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-2 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-3 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-3.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-4 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-4.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-5 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-5.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-6 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-6.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-7 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-7.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-8 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-8.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-9 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-9.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-10 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-10.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-11 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-11.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-12 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-12.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-13 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-13.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-14 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-14.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-15 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-15.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.g-img-16 {
  background-image: url('../../../../assets/home-page/testimonial-gallery-16.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
