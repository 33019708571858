.footer {
  min-height: 30vh;
  background-color: var(--main-color2-90-shade);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.grid-footer {
  display: grid;
  column-gap: 3.2rem;
  row-gap: 3.2rem;
}

.footer-container {
  width: 100%;
  margin: 0 auto;
  padding: 4.8rem;
}

.footer-logo-container {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-other-link {
  grid-column: -3 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footer-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1.6rem;
  padding: 2.4rem;
}

.footer-meta p {
  font-size: 1.4rem;
}

.footer-meta h4,
.footer-data h4 {
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
  text-align: center;
}

.footer-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1.6rem;
  padding: 2.4rem;
}

.footer-icon,
.contact-container {
  height: 2.4rem;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
  color: var(--main-color2-40-tin);
}

.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
}

.footer-copy-r {
  grid-column: -3 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.footer-copy-r a:visited,
.footer-copy-r a:link {
  text-decoration: none;
  color: var(--white);
}

.footer-copy-r a:hover,
.footer-copy-r a:active {
}

.footer-copy-r p {
  text-align: center;
}
