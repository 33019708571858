.error-fallback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 2.4rem;
  background-color: var(--dark-background-color);
}

.error-fallback h1 {
  font-size: 9.8rem;
  letter-spacing: 0.3rem;
  color: var(--white);
  text-align: center;
}

.error-fallback p {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--white-low-opacity);
  text-align: center;
}
