.suspense-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 2.4rem;
  background-color: var(--dark-background-color);
}

.suspense-spinner2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.4rem;
  background-color: var(--dark-background-color);
}
