.section-parallax-gallery-3 {
  min-height: 100vh;
}

.parallax-3 {
  /* Full height */
  height: 100vh;
  /* Create the parallax scrolling effect */
  display: flex;
  justify-content: center;
  align-items: center;
}

.para3 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
