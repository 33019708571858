.how-it-works-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  min-height: 100vh;
  background-color: var(--dark-background-color);
}

.how-it-works-img {
  background-image: url('../../../../assets/home-page/how it works placeholder img copy.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--main-color2);
}

.how-it-works-text {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  border: 1px solid var(--main-color2);
}

.how-it-works-text-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.how-it-works-text-container p {
  font-family: var(--font-family-one);
  font-size: 3rem;
  color: var(--gray-text);
  letter-spacing: 0.2rem;
  font-weight: 500;
  text-align: right;
}

.how-it-works-h4 {
  font-family: var(--font-family-one);
  font-weight: 700;
  font-size: 8rem;
  color: var(--white);
  position: absolute;
  top: 12%;
  right: -30%;
  text-align: right;
}

.how-it-works-link span {
  font-size: 2rem;
  letter-spacing: 0.2rem;
  font-family: var(--font-family-one);
  font-weight: 700;
  -webkit-text-stroke: 1px var(--main-color2);
}

.how-it-works-link:link,
.how-it-works-link:visited {
  display: inline-block;
  padding: 2rem 4rem;
  border-radius: 9px;
  background-color: var(--white);
  text-decoration: none;
  border: 1px solid var(--main-color2);
  transition: all 0.3s;
  position: absolute;
  bottom: 12%;
  left: 0;
  opacity: 0.9;
}

.how-it-works-link:hover,
.how-it-works-link:active {
  display: inline-block;
  padding: 2rem 4rem;
  border-radius: 9px;
  background-color: var(--main-color2-80-tin);
  text-decoration: none;
  border: 1px solid var(--white);
  transform: scale(1.05);
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.6);
}

.show-how-link {
  left: -10% !important;
  opacity: 1 !important;
}
