.about-section {
  background-color: var(--dark-background-color);
  min-height: 100vh;
  width: 100%;
  padding: 9.6rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.about-hero {
  display: grid;
  grid-template-rows: 35fr 65fr;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.about-hero-top {
  background-image: linear-gradient(
    to right,
    var(--main-color2),
    rgba(2, 2, 2, 0)
  );
}

.about-hero-bottom {
  background-image: linear-gradient(
    to right,
    var(--text-color-grays3),
    rgba(0, 0, 0, 0)
  );
}

.about-absolute {
  position: absolute;
  width: 60%;
  height: 75%;
  background-color: var(--dark-background-color);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 2px solid var(--main-color2);
}

.about-img-c {
  background-image: url('../../assets/about/about copy.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.about-img {
  width: 100%;
  max-height: 100%;
}

.about-content {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.about-content-h1 {
  font-family: var(--font-family-one);
  font-weight: 700;
  font-size: 4rem;
  text-align: center;
  color: var(--white);
  transition: all 1s;
  transform: translateX(15rem);
  opacity: 0;
}

.about-content-h2 {
  font-family: var(--font-family-one);
  font-weight: 500;
  font-size: 2.5rem;
  text-align: center;
  color: var(--white-low-opacity);
  transition: all 1s;
  transform: translateX(15rem);
  opacity: 0;
}

.about-content-p {
  font-family: var(--font-family-one);
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  padding-top: 3rem;
  color: var(--white-low-opacity);
  transition: all 0.5s;
  transform: translateX(15rem);
  opacity: 0;
}

.admin-btn-c {
  transition: all 0.5s;
  transform: translateX(15rem);
  opacity: 0;
}

.about-link-c {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 4.8rem;
  padding-top: 4.8rem;
  transition: all 1s;
  opacity: 0;
}

.show {
  transform: translateX(0rem);
  opacity: 1;
}

.about-a-tag:link,
.about-a-tag:visited {
  color: var(--main-color2);
}

.about-a-tag:hover,
.about-a-tag:active {
  color: var(--main-color2);
}

.about-icon-a {
  width: 3rem;
  height: 3rem;
}

.about-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4.2rem;
}

.about-body h1 {
  font-family: var(--font-family-one);
  font-weight: 700;
  font-size: 4rem;
  text-align: center;
  color: var(--white);
}

.about-body h2 {
  font-family: var(--font-family-one);
  font-weight: 500;
  font-size: 2.5rem;
  text-align: center;
  color: var(--white-low-opacity);
}

.feedback-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
}

.feedback-item {
  padding: 1rem 2rem 1rem 2rem;
  background-color: var(--text-color-grays);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
  border-radius: 9px;
}

.feedback-item h5 {
  font-family: var(--font-family-one);
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
  color: var(--white);
}

.feedback-item p {
  font-family: var(--font-family-one);
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  color: var(--white-low-opacity);
}
