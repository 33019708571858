/*

---- 01 TYPOGRAPHY SYSTEM:

    - FONT SIZE SYSTEM (px):
      10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

    - FONT WEIGHTS:
      Default: 400
      700 / 600 / 500 /

    - LINE HEIGHTS:
      Default: 1
      1.05 / 1.2 / 1.6 / 1.8

      ---- 02 COLORS:

     - Primary 1: #5808bf
     - Primary 2: #9729ca

     - Tints: #eee6f9
     - Shades:  #1a0239 #090113
     - Accents:
     - Greys: #555 #333 #fff #6f6f6f

---- 03 SHADOWS:

---- 04 BORDER RADIUS:

     9px / 11px

---- 05 WHITESPACE:

     - SPACING SYSTEM (px):
       2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

     - LETTER SPACING (px):
       0.75px / -0.5px / 1.2px / 1px
*/

/* reset 1 */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* reset 2 in order to 1 rem render to 10px */
html {
  /* 10px / 16px = 0.625 = 62.5% */
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* store variables in one place */
:root {
  --main-color1: #5808bf;
  --main-color1-90-tin: #eee6f9;
  --main-color1-90-shade: #090113;
  --main-color1-70-shade: #1a0239;
  --main-color2: #14e22c;
  --main-color2-70-shade: #06440d;
  --main-color2-90-shade: #021704;
  --main-color2-90-tin: rgba(2, 23, 4, 0.2);
  --main-color2-low-opacity: rgba(20, 226, 44, 0.4);
  --main-color2-low-opacity-20: rgba(20, 226, 44, 0.2);
  --main-color1-90-tin-low-opacity: rgba(238, 230, 249, 0.4);
  --main-color2-10-shade: #12cb28;
  --main-color2-20-shade: #10b523;
  --main-color2-20-tin: #43e856;
  --main-color2-40-tin: #72ee80;
  --main-color2-80-tin: #d0f9d5;
  --dark-background-color: #212121;
  --text-color-grays: #555;
  --text-color-grays-low-opacity: rgba(85, 85, 85, 0.9);
  --text-color-grays2: #333;
  --text-color-grays2-60-tin: #adadad;
  --text-color-grays3: #6f6f6f;
  --white: #fff;
  --gray-text: #d8d8d8;
  --red: rgba(183, 62, 62, 0.9);
  --red-lighter: rgba(252, 87, 87, 0.9);
  --white-low-opacity: rgba(255, 255, 255, 0.8);
  --font-family-one: 'Articulat CF';
}

/* reset all focus state */
*:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem var(--main-color2-low-opacity);
}

/* GENERAL CHANGE */
strong {
  font-weight: 600;
}

.App {
  font-family: 'Articulat CF', sans-serif;
  line-height: 1;
  font-weight: 400;
  overflow-x: hidden;
  color: var(--text-color-grays2);
}

/* containers */
.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 3.2rem;
}

.---padding-1-rem {
  padding: 1rem;
}

.---general-btn {
  display: inline-block;
  padding: 1rem 4rem;
  border-radius: 9px;
  text-decoration: none;
  border: 1px solid var(--main-color2);
  background-color: var(--main-color2-80-tin);
  transition: all 0.3s;
  margin-top: 2rem;
  cursor: pointer;
}

.---general-btn:hover {
  display: inline-block;
  padding: 1rem 4rem;
  border-radius: 9px;
  background-color: var(--main-color2-40-tin);
  text-decoration: none;
  border: 1px solid var(--white);
  transform: scale(1.05);
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.6);
}

.---red-btn {
  background-color: var(--red);
}

.---red-btn:hover {
  background-color: var(--red-lighter);
  color: var(--white);
}

.---disabled-btn {
  background-color: var(--text-color-grays);
}

.---disabled-btn:hover {
  background-color: var(--text-color-grays);
  color: var(--text-color-grays2);
}

.---general-btn span {
  font-size: 2rem;
  letter-spacing: 0.2rem;
  font-family: var(--font-family-one);
  font-weight: 700;
  /*-webkit-text-stroke: 1px var(--main-color2);*/
}

.--margin-top-48rem {
  margin-top: 4.8rem;
}

/*re usable flex container*/
.--flex {
  display: flex;
}

.--flex-column {
  flex-direction: column;
}

.--flex-j-c-center {
  justify-content: center;
}

.--flex-a-i-center {
  align-items: center;
}

.--flex-space-between {
  justify-content: space-between;
}

.-flex-space-around {
  justify-content: space-around !important;
  flex-wrap: wrap !important;
}

.--width-100 {
  width: 100%;
}

.--form-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.--form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4.2rem;
}

.--relative {
  position: relative;
}

/* re-usable grid classes */
.grid {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 9.6rem;
}

.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}

.grid--center-v {
  align-items: center;
}

.grid--2--col {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3--col {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4--col {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5--col {
  grid-template-columns: repeat(5, 1fr);
}

/* main layout */
.main-layout {
  /*margin-top: 9.6rem;*/
}

/* h1 */
.heading-primary {
  font-size: 5.2rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}

/****************************************/
/* css for terms, disclaimer, privacy */
/****************************************/
.---sub-page-main {
  background-color: var(--dark-background-color);
  min-height: 100vh;
  width: 100%;
  padding: 10.6rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: var(--white);
}

.---sub-page-main h1 {
  font-family: var(--font-family-one);
  font-weight: 700;
  font-size: 4rem;
  text-align: start;
  color: var(--white);
}

.---sub-page-main h2 {
  font-family: var(--font-family-one);
  font-weight: 500;
  font-size: 2.5rem;
  text-align: start;
  color: var(--white-low-opacity);
}

.---sub-page-main h3 {
  font-family: var(--font-family-one);
  font-weight: 500;
  font-size: 2.5rem;
  text-align: start;
  color: var(--white-low-opacity);
}

.---sub-page-main p {
  font-family: var(--font-family-one);
  font-weight: 400;
  font-size: 2rem;
  text-align: start;
  padding-top: 3rem;
  color: var(--white-low-opacity);
}

.---sub-page-main li {
  margin-left: 2rem;
  font-family: var(--font-family-one);
  font-weight: 400;
  font-size: 2rem;
  text-align: start;
  padding-top: 1rem;
  color: var(--white-low-opacity);
}

/***************************************/
/* language widget */
/***************************************/
.relative-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.language-widget {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 2%;
  right: 1%;
  border-radius: 30%;
  cursor: pointer;
}

.language-widget.hun {
  background-image: url('./assets/language-widget/Flag_of_Hungary.svg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 50%;
}

.language-widget.eng {
  background-image: url('./assets/language-widget/Flag_of_the_United_Kingdom_(1-2).svg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 50%;
}

.options {
  position: fixed;
  width: 3rem;
  height: 3rem;
  right: 1%;
  border-radius: 30%;
  cursor: pointer;
}

.options.hun {
  background-image: url('./assets/language-widget/Flag_of_Hungary.svg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 10%;
}

.options.eng {
  background-image: url('./assets/language-widget/Flag_of_the_United_Kingdom_(1-2).svg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  bottom: 19%;
}

.open {
  background-image: url('./assets/language-widget/Question-mark-Transparent-PNG.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.4s;
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 2%;
  right: 1%;
  border-radius: 30%;
}

@media screen and (orientation: landscape) {
  .options.hun {
    background-image: url('./assets/language-widget/Flag_of_Hungary.svg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 12%;
  }

  .options.eng {
    background-image: url('./assets/language-widget/Flag_of_the_United_Kingdom_(1-2).svg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    bottom: 23%;
  }
}
