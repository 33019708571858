@media screen and (orientation: portrait) {
  .para1 {
    background-image: url('../../../../assets/home-page/para1-vertical.jpg');
  }
}

@media screen and (orientation: landscape) {
  .para1 {
    background-image: url('../../../../assets/home-page/para1.jpg');
  }
}
