/*900px*/
@media (max-width: 56em) {
  .map-grid {
    column-gap: 3.2rem;
  }
}

/*800px*/
@media (max-width: 50em) {
  .road-map-grid--2--col,
  .road-map-grid--2--col-right {
    grid-template-columns: 1fr;
    grid-template-rows: 60fr 40fr;
  }

  .map-img-container2,
  .map-img-container4 {
    grid-row: 2 / 3;
  }

  .right {
    grid-row: 1 / 2;
  }

  .map-grid {
    row-gap: 8rem;
  }

  .map-f-img-container::before {
    width: 40rem;
    height: 40rem;
  }

  .map-f-img-container::after {
    width: 34rem;
    height: 33rem;
  }

  .section-road-map {
    padding: 6.4rem 0 12.8rem 0;
  }
}

/*700px*/
@media (max-width: 44em) {
  .map-f-img-container::before {
    width: 45rem;
    height: 45rem;
  }

  .map-f-img-container::after {
    width: 40rem;
    height: 40rem;
  }
}

/*550px*/
@media (max-width: 34em) {
  .map-f-img-container::before {
    width: 50rem;
    height: 50rem;
  }

  .map-f-img-container::after {
    width: 45rem;
    height: 45rem;
  }
}
