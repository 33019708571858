.results-section {
  background-color: var(--dark-background-color);
  min-height: 100vh;
  width: 100%;
  padding: 9.6rem 2rem 2rem 2rem;
}

.results-hero {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  gap: 2rem;
}

.results-hero-item {
  width: 100%;
  height: 100%;
}

.results-hero-img-big {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4.8rem;
  padding: 2rem;
}

.results-hero-img-big h1 {
  font-size: 12rem;
  font-weight: 700;
  font-family: var(--font-family-one);
  color: var(--main-color2);
  width: 100%;
}

.results-hero-img-big h2 {
  font-size: 6rem;
  font-weight: 700;
  font-family: var(--font-family-one);
  color: var(--white-low-opacity);
  width: 100%;
  margin-top: 3.2rem;
}

.results-hero-absolute {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform: translateX(100%);
  transition: all 1.5s ease-out;
  background-image: linear-gradient(
    to left,
    var(--main-color2-low-opacity-20),
    rgba(255, 0, 0, 0)
  );
}

.pic1 {
  background-image: url('../../assets/home-page/testimonial-gallery-1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic2 {
  background-image: url('../../assets/home-page/testimonial-gallery-2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic3 {
  background-image: url('../../assets/home-page/testimonial-gallery-3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic4 {
  background-image: url('../../assets/home-page/testimonial-gallery-4.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic5 {
  background-image: url('../../assets/home-page/testimonial-gallery-5.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic6 {
  background-image: url('../../assets/home-page/testimonial-gallery-6.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic7 {
  background-image: url('../../assets/home-page/testimonial-gallery-7.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic8 {
  background-image: url('../../assets/home-page/testimonial-gallery-8.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic9 {
  background-image: url('../../assets/home-page/testimonial-gallery-9.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic10 {
  background-image: url('../../assets/home-page/testimonial-gallery-10.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic11 {
  background-image: url('../../assets/home-page/testimonial-gallery-11.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.pic12 {
  background-image: url('../../assets/home-page/testimonial-gallery-12.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}

/*BODY*/
.results-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.2rem;
  width: 100%;
  margin-top: 2rem;
  background-color: var(--text-color-grays2);
}

.results-body h4 {
  color: var(--white);
  font-size: 4.4rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
}

.results-body h5 {
  color: var(--white-low-opacity);
  font-size: 3.2rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
}

.results-body p {
  color: var(--gray-text);
  font-size: 2.4rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
}

.body-h4 {
  color: var(--white);
  font-size: 4.4rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.body-header-res {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3.2rem;
  width: 100%;
  padding: 2rem;
  margin-bottom: 4.2rem;
}

.body-img-container {
  display: inline-block;
  width: 25rem;
  border: 1px solid var(--main-color2);
}

.body-img-face {
  max-width: 100%;
  opacity: 0.9;
}

.body-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
}

.body-img-img-c {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 3.2rem;
  flex-direction: column;
  padding: 2rem;
}

/*MODIFIERS*/
.show {
  transform: translateX(0%);
}

.fade {
  opacity: 0.1;
}
