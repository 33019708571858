/*1250px*/
@media (max-width: 78em) {
  .header {
    padding: 0 2.4rem;
  }
}

/*1100px*/
@media (min-width: 68.75em) {
  .main-sub-list {
    flex-direction: column;
  }
}

/*1200px*/
@media (max-width: 75em) {
  .main-nav-list {
    gap: 2.4rem;
  }
}

/*900px*/
@media (max-width: 56em) {
}

/*600px*/
@media (max-width: 37.5em) {
  .x-container {
    padding: 0 2.4rem;
  }
}

/*450px*/
@media (max-width: 28em) {
  .header {
    padding: 0 1.2rem;
  }

  .x-container {
    padding: 0 1.2rem;
  }
}

/*310px*/
@media (max-width: 19em) {
}
