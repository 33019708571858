.contact-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  min-height: 100vh;
  background-color: var(--dark-background-color);
}

.contact-img {
  background-image: url('../../../../assets/home-page/contact.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--main-color2);
}

.contact-text {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  border: 1px solid var(--main-color2);
}

.contact-text-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.contact-text-container p {
  font-family: var(--font-family-one);
  font-size: 3rem;
  color: var(--gray-text);
  letter-spacing: 0.2rem;
  font-weight: 500;
  text-align: right;
}

.contact-h4 {
  font-family: var(--font-family-one);
  font-weight: 700;
  font-size: 8rem;
  color: var(--white);
  position: absolute;
  top: 12%;
  right: -30%;
  text-align: right;
}

.contact-link span {
  font-size: 2rem;
  letter-spacing: 0.2rem;
  font-family: var(--font-family-one);
  font-weight: 700;
  -webkit-text-stroke: 1px var(--main-color2);
}

.contact-link:link,
.contact-link:visited {
  display: inline-block;
  padding: 2rem 4rem;
  border-radius: 9px;
  background-color: var(--white);
  text-decoration: none;
  border: 1px solid var(--main-color2);
  transition: all 0.3s;
  position: absolute;
  bottom: 12%;
  left: -10%;
}

.contact-link:hover,
.contact-link:active {
  display: inline-block;
  padding: 2rem 4rem;
  border-radius: 9px;
  background-color: var(--main-color2-80-tin);
  text-decoration: none;
  border: 1px solid var(--white);
  transform: scale(1.05);
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.6);
}

/***************** FORM + POP UPS *************************/

/*IF auto feel input it will not use the def autofill color*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--white);
}

.cta-container {
  position: relative;
}

.contact-form-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}

.contact-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.contact-field label {
  color: var(--main-color2);
  font-size: 1.8rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
}

.contact-field-field {
  width: 100%;
  background-color: var(--text-color-grays2);
  border-radius: 9px;
  height: 4rem;
  border: 1px solid var(--text-color-grays3);
  color: var(--white);
  text-indent: 1rem;
}

.contact-field-field.text-field {
  height: 16rem;
}

.contact-btn {
  display: inline-block;
  padding: 1rem 4rem;
  border-radius: 9px;
  background-color: var(--white);
  text-decoration: none;
  border: 1px solid var(--main-color2);
  transition: all 0.3s;
  transform: translateX(70%);
  opacity: 0.9;
}

.contact-btn:hover {
  display: inline-block;
  padding: 1rem 4rem;
  border-radius: 9px;
  background-color: var(--main-color2-80-tin);
  text-decoration: none;
  border: 1px solid var(--white);
  transform: scale(1.05);
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.6);
}

.animate-contact-btn {
  transform: translateX(0) !important;
  opacity: 1 !important;
}

.contact-btn span {
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-family: var(--font-family-one);
  font-weight: 700;
  -webkit-text-stroke: 1px var(--main-color2);
}

/******************************/
/* pop up
/******************************/
.pop-up {
  background-color: var(--main-color2-20-shade);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  transition: all 1s ease-in;
  padding: 2rem;
  position: absolute;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  transform: translateX(0%);
  pointer-events: auto;
  visibility: visible;
  color: var(--white);
}

.relative-container {
  position: relative;
}

.hidden-pop-up {
  opacity: 0;
  transform: translateX(100%);
}

.img-container {
  width: 30%;
}

.success-img {
  width: 100%;
  opacity: 0.7;
}

.pop-up h3 {
  font-size: 3.2rem;
  letter-spacing: 0.3rem;
  text-align: center;
}

.pop-up p {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 3.2rem;
  text-align: center;
  line-height: 1.4;
}

/******************************/
/* error up
/******************************/
.error-up {
  background-color: #a13939;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  transition: all 1s ease-in;
  padding: 2rem;
  position: absolute;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  transform: translateX(0%);
  pointer-events: auto;
  visibility: visible;
  color: var(--white);
}

.hidden-error-up {
  opacity: 0;
  transform: translateX(-100%);
}

.error-up h3 {
  font-size: 3.2rem;
  letter-spacing: 0.3rem;
  text-align: center;
}

.error-up p {
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 3.2rem;
  text-align: center;
}
