@media screen and (orientation: portrait) {
  /*1200px*/
  @media (max-width: 75em) {
    .services-text-container {
      margin-bottom: 4rem;
    }

    .services-section {
      grid-template-columns: 70fr 30fr;
      grid-template-rows: 40fr 60fr;
    }

    .services-img {
      grid-row: 1 / 2;
      border: 1px solid var(--main-color2);
    }

    .services-01 {
      border: 1px solid var(--main-color2);
    }

    .services-text {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
    }

    .services-h4 {
      top: -22%;
      left: unset;
      font-size: 6.2rem;
    }

    .services-link:link,
    .services-link:visited {
      position: unset;
      padding: 1.5rem 2.5rem;
    }

    .services-link span {
      font-size: 1.3rem;
      letter-spacing: 0.2rem;
      font-family: var(--font-family-one);
      font-weight: 700;
      -webkit-text-stroke: 1px var(--main-color2);
    }
  }

  /*700px*/
  @media (max-width: 44em) {
    .services-link:link,
    .services-link:visited {
      margin-bottom: 2rem;
    }

    .services-h4 {
      font-size: 6.2rem;
    }

    .services-text-container {
      padding: 2rem;
      justify-content: space-evenly;
    }

    .services-text-container p {
      font-size: 2rem;
    }
  }

  /*500px*/
  @media (max-width: 31em) {
    .services-h4 {
      font-size: 5rem;
    }

    .services-text-container p {
      font-size: 1.5rem;
    }

    .services-link:link,
    .services-link:visited {
      padding: 1rem 2rem;
    }

    .services-link span {
      font-size: 1rem;
    }

    .services-text-container {
      gap: 1rem;
    }
  }

  /*400px*/
  @media (max-width: 25em) {
    .services-h4 {
      font-size: 3.6rem;
    }
  }
}

@media screen and (orientation: landscape) {
  /*1900px*/
  @media (max-width: 119em) {
    .services-h4 {
      font-size: 4.2rem;
    }
  }

  /*1550px*/
  @media (max-width: 97em) {
    .services-text-container p {
      font-size: 2.5rem;
    }
  }

  /*1200px*/
  @media (max-width: 75em) {
    .services-section {
      grid-template-columns: 70fr 30fr;
      grid-template-rows: 40fr 60fr;
    }

    .services-img {
      grid-row: 1 / 2;
      border: 1px solid var(--main-color2);
    }

    .services-01 {
      border: 1px solid var(--main-color2);
    }

    .services-text {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }

    .services-h4 {
      top: -22%;
      left: unset;
      font-size: 5rem;
    }

    .services-link:link,
    .services-link:visited {
      position: unset;
      padding: 1.5rem 2.5rem;
      margin-bottom: 1rem;
    }

    .services-link span {
      font-size: 1.3rem;
      letter-spacing: 0.2rem;
      font-family: var(--font-family-one);
      font-weight: 700;
      -webkit-text-stroke: 1px var(--main-color2);
    }

    .services-text-container p {
      font-size: 1.5rem;
    }

    .services-text-container {
      padding: 2rem;
      justify-content: space-evenly;
      gap: 1.5rem;
      margin-bottom: 2rem;
    }
  }

  /*700px*/
  @media (max-width: 44em) {
    .services-link:link,
    .services-link:visited {
      padding: 1rem 2rem;
      margin-bottom: 2rem;
    }

    .services-section {
      grid-template-columns: 70fr 30fr;
      grid-template-rows: 40fr 60fr;
    }
  }

  /*500px*/
  @media (max-width: 31em) {
    .services-link span {
      font-size: 1rem;
    }

    .services-text-container {
      gap: 1rem;
    }
  }

  /*400px*/
  @media (max-width: 25em) {
    .services-link span {
      font-size: 0.7rem;
    }

    .services-text-container {
      gap: 0.5rem;
    }

    .services-section {
      grid-template-columns: 70fr 30fr;
      grid-template-rows: 25fr 75fr;
    }
  }
}
