@media screen and (orientation: portrait) {
  /*1400px*/
  @media (max-width: 87.5em) {
    .booking-content p {
      font-size: 2rem;
    }

    .booking-free {
      width: 23%;
      height: 5%;
      transform: rotate(45deg) translateY(-40%) translateX(30%);
    }
  }

  /*1000px*/
  @media (max-width: 68.75em) {
    .booking-header-h4 {
      font-size: 6rem;
    }
  }

  /*950px*/
  @media (max-width: 59em) {
    .booking-free {
      transform: rotate(45deg) translateY(-40%) translateX(28%);
    }

    .booking-content p {
      font-size: 1.8rem;
    }

    .booking-free span {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  /*800px*/
  @media (max-width: 50em) {
    /* change the layout here*/
    .booking-grid {
      display: grid;
      width: 100%;
      min-height: 100vh;
      grid-template-columns: 1fr;
      padding: 8rem 6rem 8rem 6rem;
      gap: 2rem;
      position: relative;
    }

    .booking-img-container-grid {
      padding: 0;
    }

    .booking-text-container-grid {
      padding: 0;
    }

    .booking-vertical-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .booking-grid::before {
      display: none;
      visibility: hidden;
    }

    .booking-grid::after {
      width: 100%;
    }

    .booking-content p {
      font-size: 3.6rem;
    }

    .booking-free {
      height: 3%;
      transform: rotate(45deg) translateY(-40%) translateX(28%);
    }

    .booking-header-h4 {
      font-size: 5rem;
      margin-bottom: 2rem;
    }
  }

  /*700px*/
  @media (max-width: 43.75em) {
    .booking-header-h4 {
      font-size: 4rem;
    }

    .booking-free {
      height: 3%;
      transform: rotate(45deg) translateY(-40%) translateX(28%);
    }
  }

  /*600px*/
  @media (max-width: 37.5em) {
    .booking-header-h4 {
      font-size: 3.2rem;
      text-align: center;
    }

    .booking-free span {
      font-size: 1rem;
    }

    .booking-content p {
      font-size: 3rem;
    }
  }

  /*500px*/
  @media (max-width: 31.25em) {
    .booking-grid {
      padding: 8rem 4rem 8rem 4rem;
    }

    .booking-free {
      height: 2.5%;
      transform: rotate(45deg) translateY(-40%) translateX(28%);
    }
  }

  /*450px*/
  @media (max-width: 28.13em) {
    .booking-free {
      width: 35%;
      height: 2%;
      transform: rotate(45deg) translateY(-35%) translateX(28%);
    }
  }

  /*400px*/
  @media (max-width: 25em) {
    .booking-content p {
      font-size: 2rem;
    }
  }
}

@media screen and (orientation: landscape) {
  /*1400px*/
  @media (max-width: 87.5em) {
    .booking-content p {
      font-size: 2rem;
    }
  }

  /*1000px*/
  @media (max-width: 68.75em) {
    .booking-header-h4 {
      font-size: 6rem;
    }
  }

  /*950px*/
  @media (max-width: 59em) {
    .booking-content p {
      font-size: 1.8rem;
    }

    .booking-header-h4 {
      font-size: 6rem;
    }

    .booking-free span {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  /*820px*/
  @media (max-width: 51em) {
    .booking-header-h4 {
      font-size: 5rem;
    }

    .booking-content p {
      font-size: 1.4rem;
    }
  }

  /*700px*/
  @media (max-width: 43.75em) {
    .booking-header-h4 {
      font-size: 4rem;
    }
  }

  /*600px*/
  @media (max-width: 37.5em) {
    .booking-header-h4 {
      font-size: 3.2rem;
    }

    .booking-free span {
      font-size: 1rem;
    }

    .booking-free {
      height: 3%;
    }
  }
}
