@media screen and (orientation: portrait) {
  /*1250px*/
  @media (max-width: 78em) {
    .about-absolute {
      width: 75%;
      height: 75%;
    }
  }

  /*900px*/
  @media (max-width: 57em) {
    .about-content h1 {
      font-size: 3rem;
    }

    .about-content h2 {
      font-size: 2rem;
    }

    .about-content p {
      font-size: 1.6rem;
    }
    .about-absolute {
      width: auto;
      height: auto;
    }
  }

  /*600px*/
  @media (max-width: 37.5em) {
    .about-absolute {
      grid-template-columns: 1fr;
      grid-template-rows: 30fr 70fr;
    }

    .about-img-c {
      background-image: url('../../assets/about/about copy.jpg');
      background-position: right 100% bottom 85%;
    }

    .about-link-c {
      padding-top: 2rem;
    }

    .about-content p {
      padding-top: 2.1rem;
    }
  }
}

@media screen and (orientation: landscape) {
  /*1250px*/
  @media (max-width: 78em) {
    .about-absolute {
      width: 75%;
      height: 75%;
    }
  }

  /*900px H */
  @media (max-height: 57em) {
    .about-absolute {
      width: 75%;
      height: 90%;
    }
  }
  /*900px*/
  @media (max-width: 57em) {
    .about-content h1 {
      font-size: 3rem;
    }

    .about-content h2 {
      font-size: 2rem;
    }

    .about-content p {
      font-size: 1.6rem;
    }
    .about-absolute {
      width: auto;
      height: auto;
    }
  }

  /*650px H */
  @media (max-height: 39em) {
    .about-absolute {
      width: 100%;
      height: 100%;
      position: unset;
    }

    .about-img-c {
      background-image: url('../../assets/about/about copy.jpg');
    }

    .about-link-c {
      padding-top: 2rem;
    }

    .about-content p {
      padding-top: 2.1rem;
    }

    .about-hero {
      grid-template-rows: 1fr;
    }
  }
}
